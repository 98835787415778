import type { WidgetTags } from "constants/WidgetConstants";
import { WIDGET_TAGS } from "constants/WidgetConstants";

export const metaConfig = {
  name: "Paragraph",
  tags: [WIDGET_TAGS.CONTENT] as WidgetTags[],
  searchTags: [
    "type",
    "paragraph",
    "text",
    "content",
    "prose",
    "description",
    "multiline",
    "multi-line",
  ],
};
