export const settersConfig = {
  __setters: {
    setVisibility: {
      path: "isVisible",
      type: "boolean",
    },
    setDisabled: {
      path: "isDisabled",
      type: "boolean",
    },
    setLabel: {
      path: "text",
      type: "string",
    },
    setColor: {
      path: "buttonColor",
      type: "string",
    },
  },
};
