export const anvilConfig = {
  isLargeWidget: false,
  widgetSize: {
    maxWidth: {
      base: "100%",
      "280px": "sizing-70",
    },
    minWidth: "sizing-9",
  },
};
